import React from 'react'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import '../templates/base.scss'
import Layout from '../components/Layout/Layout'
import SEO from '../components/SEO'
import DigitalAgilityContent from '../components/DigitalAgility/DigitalAgilityContent/DigitalAgilityContent'
import DigitalAgilityHero from '../components/DigitalAgility/DigitalAgilityHero/DigitalAgilityHero'

const CloudSecOps = ({ data }) => {
  const postNode = {
    title: `CloudSecOps - ${config.siteTitle}`,
  }

  return (
    <Layout>
      <SEO postNode={postNode} pagePath="cloudsecops" customTitle />
      <Helmet>
        <title>{`CloudSecOps - ${config.siteTitle}`}</title>
      </Helmet>
      <DigitalAgilityHero />
      <DigitalAgilityContent />
    </Layout>
  )
}

export default CloudSecOps
