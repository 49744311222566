import React from 'react'
import './DigitalAgilityContent.scss'
import CallToActionBanner from '../../CallToActionBanner/CallToActionBanner'
import * as Fa from 'react-icons/fa'

export class DigitalAgilityContent extends React.Component {
  render() {
    return (
      <div className="digital-agility-content">
        <div className="split light subheader-block">
          <div className="text-wrap">
            We build future-proof platforms. Rackner helps organizations like
            the US Air Force with large-scale product development and AI
            capabilities throughout the mission lifecycle
          </div>
        </div>
        <section className="padding-large">
          <div className="row">
            <div className="col-md-6">
              <h3>AI & ML Development</h3>
              <div className="service-row">
                <div className="text-wrap">
                  <div className="title">ML Approach Development</div>
                  <p>
                    Building a data-forward solution using existing and new
                    models.
                  </p>
                </div>
              </div>
              <div className="service-row">
                <div className="text-wrap">
                  <div className="title">AutoML and MLOps Infrastructure</div>
                  <p>
                    We build ML solutions with constant retraining and
                    improvement. The majority of value in AI comes from
                    retraining and better labeling.
                  </p>
                  <div className="key-tools">
                    <span>Key Tech & Partners:</span> Kubeflow, MLFlow
                  </div>
                </div>
              </div>
              <div className="service-row">
                <div className="text-wrap">
                  <div className="title">Business Case Application</div>
                  <p>
                    AI without a business impact is useless. We focus on
                    building ML enabled solutions that increase the efficiency
                    and efficacy of the organization
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <img src="/rocket_laptop.svg" />
            </div>
          </div>
        </section>
        <div className="split light no-padding">
          <section className="padding-large">
            <div className="row">
              <div className="col-md-6 col-md-push-6">
                <h3>API & Microservice Development</h3>
                <div className="service-row">
                  <div className="text-wrap">
                    <div className="title">Modern API Development</div>
                    <p>Flexible, clean, well documented APIs</p>
                    <div className="key-tools">
                      <span>Key Tech & Partners:</span> Language-specific
                      frameworks, Swagger
                    </div>
                  </div>
                </div>
                <div className="service-row">
                  <div className="text-wrap">
                    <div className="title">API Management</div>
                    <p>
                      Bake in rate limiting, licensing, and other features to
                      your API for consumption
                    </p>
                    <div className="key-tools">
                      <span>Key Tech & Partners:</span> AWS API Gateway, Kong,
                      Ambassador
                    </div>
                  </div>
                </div>
                <div className="service-row">
                  <div className="text-wrap">
                    <div className="title">Logging & Monitoring</div>
                    <p>
                      Hook in APM & logging infrastructure to optimize for user
                      experience.
                    </p>
                    <div className="key-tools">
                      <span>Key Tech & Partners:</span> DataDog, AppDynamics,
                      New Relic, CloudWatch
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-md-pull-6">
                <img src="/api_illustration.svg" />
              </div>
            </div>
          </section>
        </div>
        <section className="padding-large">
          <div className="row">
            <div className="col-md-6">
              <h3>Enterprise Product Development & Modernization</h3>
              <div className="service-row">
                <div className="text-wrap">
                  <div className="title">SaaS Development</div>
                  <p>
                    From billing and free trials to secure multi tenancy and
                    freemium models, we're experienced in dealing with all the
                    aspects of building a SaaS platform.
                  </p>
                  <div className="key-tools">
                    <span>Key Tech & Partners:</span> Kubernetes, Amazon ECS
                  </div>
                </div>
              </div>
              <div className="service-row">
                <div className="text-wrap">
                  <div className="title">Application Modernization</div>
                  <p>
                    Move from slow-loading pages to a blazing fast applications,
                    and replatform to a cloud-native solution.
                  </p>
                  <div className="key-tools">
                    <span>Key Tech & Partners:</span> React, Kubernetes,
                    Containers, Public & Private Clouds
                  </div>
                </div>
              </div>
              <div className="service-row">
                <div className="text-wrap">
                  <div className="title">Enterprise Platform Security</div>
                  <p>
                    Audited for security using industry-leading tools.
                    Constantly monitored for breaches throughout the stack.
                  </p>
                  <div className="key-tools">
                    <span>Key Tech & Partners:</span> Tenable, Qualys, Rapid7,
                    AWS Inspector
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <img src="/enterpriseapp2.svg" />
            </div>
          </div>
        </section>
        <div className="split pattern">
          <h1>Product Design</h1>
          <div className="row migration-paths">
            <div className="col-sm-4">
              <div className="path">
                <div className="title">Workflows</div>
                <div className="icon-wrap">
                  <img src="/workflows.svg" />
                </div>
                <div className="description">
                  We start with use cases and user workflows, mapping out how
                  users will interact with the application.
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="path">
                <div className="title">Wireframes</div>
                <div className="icon-wrap">
                  <img src="/wireframe.svg" />
                </div>
                <div className="description">
                  Next, wireframes are created to show where major UI elements
                  will reside on mobile, tablet, and desktop views.
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="path">
                <div className="title">Mockups</div>
                <div className="icon-wrap">
                  <img src="/mockup.svg" />
                </div>
                <div className="description">
                  Finally, high fidelity mockups are created, along with
                  specific animations and interaction design.
                </div>
              </div>
            </div>
          </div>
        </div>

        <CallToActionBanner route={'insights'} linkText={'See Case Studies'} />
      </div>
    )
  }
}

export default DigitalAgilityContent
