import React from 'react'
import './DigitalAgilityHero.scss'

export class DigitalAgilityHero extends React.Component {
  render() {
    return (
      <div
        className="digital-page-hero"
        style={{ backgroundImage: 'url(/cloudsecopsbg.jpg)' }}
      >
        <div className="overlay" />
        <div className="inner-wrap">
          <div className="text">
            <h1>AI & Platform Development</h1>
            <h2>
            Develop or modernize business-critical 
                applications with AI Capabilities
            </h2>
          </div>
        </div>
      </div>
    )
  }
}

export default DigitalAgilityHero
